import { render, staticRenderFns } from "./tatics-item.vue?vue&type=template&id=7470b856&scoped=true"
import script from "./tatics-item.vue?vue&type=script&lang=ts"
export * from "./tatics-item.vue?vue&type=script&lang=ts"
import style0 from "./tatics-item.vue?vue&type=style&index=0&id=7470b856&prod&lang=less&scoped=true"
import style1 from "./tatics-item.vue?vue&type=style&index=1&id=7470b856&prod&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7470b856",
  null
  
)

export default component.exports