var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { Emit, Prop } from "vue-property-decorator";
import { State, namespace } from "vuex-class";
import FileUpload from "~/components/common/file-upload.vue";
import { StrategyConfigService } from "~/services/management-service/strategy-config.service";
var taticManageModule = namespace("tatic-manage");
var ImportTatics = /** @class */ (function (_super) {
    __extends(ImportTatics, _super);
    function ImportTatics() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.fileList = [];
        _this.loading = {
            state: false
        };
        _this.editModel = {
            // 策略名称
            name: "",
            // 策略类型
            strategyType: "",
            // 优先级
            priority: "",
            // 委托方ID
            principalId: "",
            // 委托方名称
            principalName: "",
            // 文件Id
            fileId: ""
        };
        _this.dialog = {
            inrush: false,
            preview: false
        };
        _this.editRules = {
            name: [{ required: true, message: "请输入策略名称", trigger: "blur" }],
            priority: [
                {
                    required: true,
                    type: "number",
                    message: "请选择优先级",
                    trigger: "change"
                }
            ],
            strategyType: [
                {
                    required: true,
                    type: "string",
                    message: "请选择策略类型",
                    trigger: "change"
                }
            ],
            principalId: [
                {
                    required: true,
                    type: "string",
                    message: "请选择委托方",
                    trigger: "change"
                }
            ],
            organization: [
                {
                    required: true,
                    type: "string",
                    message: "请选择分公司",
                    trigger: "change"
                }
            ]
        };
        return _this;
    }
    ImportTatics.prototype.close = function () {
        this.reset();
    };
    ImportTatics.prototype.refreshList = function () { };
    ImportTatics.prototype.mounted = function () { };
    /**
     * 提交操作
     */
    ImportTatics.prototype.commit = function () {
        var _this = this;
        var form = this.$refs.form;
        form.validate(function (valid) {
            if (!valid)
                return false;
            if (_this.fileList.length > 0) {
                _this.editModel.fileId = _this.fileList[0].response.id;
            }
            _this.loading.state = true;
            _this.strategyConfigService
                .strategyImport(_this.editModel, _this.loading)
                .subscribe(function (data) {
                _this.$message.success("操作成功");
                _this.refreshList();
                _this.close();
            });
        });
    };
    ImportTatics.prototype.selectChange = function (value) {
        var currentPrincipal = this.principalList.find(function (v) { return v.id === value; });
        this.editModel.principalName = (currentPrincipal || {}).principalName;
    };
    ImportTatics.prototype.refresh = function () {
        var upload = this.$refs['file-upload'];
        upload.reset();
    };
    ImportTatics.prototype.reset = function () {
        var form = this.$refs.form;
        form.resetFields();
    };
    __decorate([
        Dependencies(StrategyConfigService)
    ], ImportTatics.prototype, "strategyConfigService", void 0);
    __decorate([
        State
    ], ImportTatics.prototype, "principalList", void 0);
    __decorate([
        State
    ], ImportTatics.prototype, "storeList", void 0);
    __decorate([
        taticManageModule.State("tacticsFlag")
    ], ImportTatics.prototype, "tacticsFlag", void 0);
    __decorate([
        taticManageModule.Mutation("updateTacticsFlag")
    ], ImportTatics.prototype, "updateTacticsFlag", void 0);
    __decorate([
        Emit("close")
    ], ImportTatics.prototype, "close", null);
    __decorate([
        Emit("refreshList")
    ], ImportTatics.prototype, "refreshList", null);
    __decorate([
        Prop()
    ], ImportTatics.prototype, "strategyId", void 0);
    ImportTatics = __decorate([
        Component({
            components: {
                FileUpload: FileUpload
            }
        })
    ], ImportTatics);
    return ImportTatics;
}(Vue));
export default ImportTatics;
