var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { Dependencies } from "~/core/decorator";
import NumberRange from "~/components/common/number-range.vue";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { StrategyService } from "~/services/manage-service/strategy.service";
import { Emit } from "vue-property-decorator";
var TaticsPreview = /** @class */ (function (_super) {
    __extends(TaticsPreview, _super);
    function TaticsPreview() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.model = {
            principalIds: [],
            strategyType: '',
            cities: '',
            formulaJson: ''
        };
        _this.strategyProSet = [
            {
                leaf: true,
                variable: '',
                value: '',
                symbol: '',
                relation: '',
                bracket: '',
                canright: false,
                variableName: ''
            }
        ];
        _this.dataSet = [];
        _this.name = '';
        return _this;
    }
    TaticsPreview.prototype.close = function () { };
    Object.defineProperty(TaticsPreview.prototype, "rightNumber", {
        get: function () {
            var left = 0;
            var right = 0;
            this.strategyProSet.forEach(function (value, index) {
                if (value.bracket.includes('(')) {
                    left++;
                }
                if (value.bracket.includes(')')) {
                    right++;
                }
            });
            if (left > right) {
                return left - right;
            }
            else {
                return 0;
            }
        },
        enumerable: false,
        configurable: true
    });
    TaticsPreview.prototype.refresh = function (obj, editObj) {
        var formulaJsonFlag = true;
        if (obj.length === 1) {
            if (obj[0].bracket === '' || obj[0].symbol === '' || obj[0].value === '' || obj[0].variable === '') {
                formulaJsonFlag = false;
            }
        }
        this.model.principalIds = editObj.principalIds;
        this.model.strategyType = editObj.strategyType;
        this.model.cities = editObj.cities;
        this.model.formulaJson = formulaJsonFlag ? JSON.stringify(obj) : '';
        this.refreshData();
        this.strategyProSet = obj;
        this.strategyProSet.map(function (x) {
            switch (x.variable) {
                case "overdueAmtTotal": return x.variableName = '委案金额';
                case "leftAmt": return x.variableName = '欠款金额';
                case "overduePeriods": return x.variableName = '逾期期数';
                case "overdueDays": return x.variableName = '逾期天数';
                case "delegationDate": return x.variableName = '委案日期';
                case "endCaseDate": return x.variableName = '退案日期';
            }
        });
    };
    TaticsPreview.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        if (this.model.strategyType === "WAIT_DISTRIBUTE") {
            this.strategyService.strategyPreview(this.model, this.pageService, this.sortService, this.loading).subscribe(function (data) {
                if (data)
                    return _this.dataSet = data;
            }, function (_a) {
                var msg = _a.msg;
            });
        }
        else {
            this.strategyService.caseStrategyPreview(this.model, this.pageService, this.sortService, this.loading).subscribe(function (data) {
                if (data)
                    return _this.dataSet = data;
            }, function (_a) {
                var msg = _a.msg;
            });
        }
    };
    __decorate([
        Dependencies(PageService)
    ], TaticsPreview.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], TaticsPreview.prototype, "sortService", void 0);
    __decorate([
        Dependencies(StrategyService)
    ], TaticsPreview.prototype, "strategyService", void 0);
    __decorate([
        Emit('close')
    ], TaticsPreview.prototype, "close", null);
    TaticsPreview = __decorate([
        Component({
            components: {
                NumberRange: NumberRange,
                DataForm: DataForm,
                DataBox: DataBox
            }
        })
    ], TaticsPreview);
    return TaticsPreview;
}(Vue));
export default TaticsPreview;
