var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { UserService } from "~/services/manage-service/user.service";
import DataBox from "~/components/common/data-box.vue";
import DepartmentTree from "~/components/system-manage/department-tree.vue";
import { StrategyService } from "~/services/manage-service/strategy.service";
import DataForm from "~/components/common/data-form.vue";
import { State, Getter } from "vuex-class";
import clone from "clone";
import AwaitCasetype from "~/components/tatics-manage/case-tactics/edit-tatics/await-casetype.vue";
var InrushTatics = /** @class */ (function (_super) {
    __extends(InrushTatics, _super);
    function InrushTatics() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.loading = {
            state: false
        };
        _this.dialog = {
            caseTypeDialog: false
        };
        _this.firstOpenFlag = false; //是否首次进入
        _this.allotType = 1;
        _this.isComplex = 0;
        _this.searchModel = {
            realName: "",
            userName: "",
            departCode: "",
            status: "WORKING",
            isShowAll: 1
        };
        _this.selectionList = [];
        _this.userDataSet = null;
        _this.departDataSet = null;
        _this.nowNode = null;
        _this.addOrModify = {};
        _this.currentDept = null;
        _this.defaultCheckedId = [];
        //是否显示多选框
        _this.showSelectBox = false;
        //选择的分配部门的列表
        _this.organizationDataSet = [];
        //选择的人员
        _this.selectionUserList = [];
        //人员Data
        _this.userDataList = [];
        _this.tactics = {
            name: "",
            priority: "",
            strategyType: "",
            caseType: null,
            strategyJson: [],
            principalIds: [],
            city: "",
            id: ""
        };
        _this.caseTypeShow = ""; //反显调解方式数据
        return _this;
    }
    InrushTatics.prototype.close = function () {
        this.userDataSet = [];
        this.userDataList = [];
        this.organizationDataSet = [];
        this.selectionUserList = [];
        this.tactics.id = "";
        this.defaultCheckedId = [];
        this.searchModel.userName = "";
        this.searchModel.realName = "";
        if (this.allotType === 1) {
            var dataBox = this.$refs["user-data-box"];
            dataBox.clearSelection(); //使用了分页记忆会记忆选中项 所以需要在成功或是关闭回调时清空组件选择项
        }
    };
    InrushTatics.prototype.success = function () {
        this.tactics.id = "";
        this.userDataSet = [];
        this.userDataList = [];
        this.organizationDataSet = [];
        this.selectionUserList = [];
        this.defaultCheckedId = [];
        this.searchModel.userName = "";
        this.searchModel.realName = "";
        if (this.allotType === 1) {
            var dataBox = this.$refs["user-data-box"];
            dataBox.clearSelection(); //使用了分页记忆会记忆选中项 所以需要在成功或是关闭回调时清空组件选择项
        }
    };
    Object.defineProperty(InrushTatics.prototype, "divisionIsEdited", {
        get: function () {
            return this.isComplex === 0 ? true : false;
        },
        enumerable: false,
        configurable: true
    });
    InrushTatics.prototype.allotTypeChange = function (value) {
        if (value === 0) {
            this.showSelectBox = true;
        }
        else {
            this.showSelectBox = false;
        }
    };
    InrushTatics.prototype.divisionTypeChanged = function (data) {
        this.organizationDataSet.forEach(function (x) {
            x.limitNum = 0;
            x.limitAmt = 0;
        });
        this.userDataList.forEach(function (x) {
            x.limitNum = 0;
            x.limitAmt = 0;
        });
    };
    InrushTatics.prototype.reset = function () {
        var tree = this.$refs["department-tree"];
        tree.reset();
    };
    InrushTatics.prototype.handleNodeClick = function (node) {
        if (this.allotType === 1) {
            this.searchModel.departCode = node.departCode;
            this.refreshUserData();
        }
    };
    InrushTatics.prototype.resetForm = function () {
        var form = this.$refs["user-form"];
        form.resetFields();
        this.searchModel.userName = "";
        this.searchModel.realName = "";
    };
    InrushTatics.prototype.refreshUserData = function () {
        var _this = this;
        this.userService
            .findUserByDepartmentCode(this.searchModel, this.sortService)
            .subscribe(function (data) {
            _this.userDataList = data;
            _this.userDataList = data.map(function (item) {
                return Object.assign({ limitNum: 0, limitAmt: 0 }, item);
            });
            if (_this.editTaicsData.models &&
                _this.editTaicsData.type === "COLLECTOR" &&
                _this.firstOpenFlag === true) {
                _this.selectionUserList = [];
                _this.firstOpenFlag = false; //初次回显选中数据后将标识置为false不再进入该判断  用户可以继续勾选新的数据而不被影响
                _this.editTaicsData.models.forEach(function (x) {
                    _this.userDataList.forEach(function (v) {
                        if (v.id === x.id) {
                            v.amount = x.amount;
                            v.limitAmt = x.amount;
                            v.limitNum = x.number;
                            v.number = x.number;
                            _this.selectionUserList.push(v);
                        }
                    });
                });
            }
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    InrushTatics.prototype.handleCheckChange = function (data, isChecked) {
        var params = clone(data);
        if (params.children)
            delete params.children;
        var findIndex = this.organizationDataSet.findIndex(function (x) { return x.id === params.id; });
        if (isChecked) {
            if (findIndex < 0) {
                this.organizationDataSet.push(Object.assign({ limitNum: 0, limitAmt: 0 }, params));
            }
        }
        else {
            if (findIndex > -1)
                this.organizationDataSet.splice(findIndex, 1);
        }
    };
    //数据回显
    InrushTatics.prototype.refresh = function (editTaicsData) {
        var _this = this;
        this.defaultCheckedId = [];
        this.organizationDataSet = [];
        this.userDataList = [];
        this.tactics.name = editTaicsData.name;
        this.tactics.priority = editTaicsData.priority;
        this.tactics.principalIds = editTaicsData.principalIds;
        this.tactics.strategyType = editTaicsData.strategyType;
        this.tactics.strategyJson = editTaicsData.strategyProSet;
        this.tactics.cities = editTaicsData.cities;
        this.tactics.id = editTaicsData.id ? this.editTaicsData.id : "";
        this.allotType = editTaicsData.type === "DEPARTMENT" ? 0 : 1;
        this.isComplex = editTaicsData.isComplex === "YES" ? 0 : 1;
        this.showSelectBox = this.allotType === 0 ? true : false;
        this.caseTypeShow = editTaicsData.caseType;
        this.tactics.caseType = editTaicsData.caseType;
        if (this.allotType === 0 && editTaicsData.models) {
            editTaicsData.models.forEach(function (x) {
                _this.defaultCheckedId.push(x.id);
                _this.organizationDataSet.push(Object.assign({ limitNum: x.number, limitAmt: x.amount }, x));
            });
        }
        var elTree = this.$refs.elTree;
        elTree.setCheckedKeys(this.defaultCheckedId);
        this.searchModel.departCode = this.orgTreeData[0].departCode; //默认部门code为登录角色部门code
        this.firstOpenFlag = true; //仅在每行数据回显时标识为true 用户树渲染时根据标识在右侧databox组件回显选中
        this.refreshUserData();
    };
    // 调解方式选择确定按钮
    InrushTatics.prototype.typeSelected = function () {
        var aaa = this.$refs["await-casetype"];
        this.tactics.caseType = aaa.datas();
        this.dialog.caseTypeDialog = false;
        this.saveData();
        this.tactics.caseType = null;
    };
    InrushTatics.prototype.closeCaseDialog = function () { };
    //完成提交
    InrushTatics.prototype.confirm = function () {
        if (this.tactics.strategyType === "WAIT_DISTRIBUTE") {
            this.dialog.caseTypeDialog = true;
            if (!this.tactics.caseType) {
                this.$message("待分配案件需选择调解方式");
            }
            return;
        }
        this.saveData();
    };
    //保存数据
    InrushTatics.prototype.saveData = function () {
        var _this = this;
        var formulaJsonFlag = true;
        if (this.tactics.strategyJson.length === 1) {
            if (this.tactics.strategyJson[0].bracket === "" ||
                this.tactics.strategyJson[0].symbol === "" ||
                this.tactics.strategyJson[0].value === "" ||
                this.tactics.strategyJson[0].variable === "") {
                formulaJsonFlag = false;
            }
        }
        var obj = {
            name: this.tactics.name,
            formulaJson: formulaJsonFlag
                ? JSON.stringify(this.tactics.strategyJson)
                : "",
            priority: this.tactics.priority,
            strategyType: this.tactics.strategyType,
            caseType: this.tactics.caseType,
            principalIds: this.tactics.principalIds,
            cities: this.tactics.cities,
            type: this.allotType === 1 ? "COLLECTOR" : "DEPARTMENT",
            isComplex: this.isComplex === 1 ? "NO" : "YES",
            models: [],
            id: ""
        };
        if (this.allotType === 0 && this.organizationDataSet.length == 0) {
            this.$message.info("请选择要分配的机构");
            return;
        }
        if (this.allotType === 1 && this.selectionUserList.length == 0) {
            this.$message.info("请选择要分配的人员");
            return;
        }
        if (this.allotType === 1) {
            this.selectionUserList.map(function (v) {
                obj.models.push({
                    id: v.id,
                    number: Number(v.limitNum),
                    amount: Number(v.limitAmt)
                });
            });
        }
        else {
            if (this.organizationDataSet.length) {
                this.organizationDataSet.map(function (v) {
                    obj.models.push({
                        id: v.id,
                        name: v.name,
                        number: Number(v.limitNum),
                        amount: Number(v.limitAmt)
                    });
                });
            }
        }
        this.loading.state = true;
        if (this.tactics.id) {
            obj.id = this.tactics.id;
            this.strategyService.modifyStrategy(obj, this.loading).subscribe(function (data) {
                _this.$message.success("修改策略成功");
                _this.success();
            });
        }
        else {
            this.strategyService.addStrategy(obj, this.loading).subscribe(function (data) {
                _this.$message.success("添加策略成功");
                _this.success();
            });
        }
    };
    __decorate([
        Dependencies(PageService)
    ], InrushTatics.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], InrushTatics.prototype, "sortService", void 0);
    __decorate([
        Dependencies(UserService)
    ], InrushTatics.prototype, "userService", void 0);
    __decorate([
        Dependencies(StrategyService)
    ], InrushTatics.prototype, "strategyService", void 0);
    __decorate([
        Getter
    ], InrushTatics.prototype, "orgTreeData", void 0);
    __decorate([
        Emit("close")
    ], InrushTatics.prototype, "close", null);
    __decorate([
        Emit("success")
    ], InrushTatics.prototype, "success", null);
    __decorate([
        State
    ], InrushTatics.prototype, "departmentList", void 0);
    __decorate([
        Prop()
    ], InrushTatics.prototype, "editTaicsData", void 0);
    InrushTatics = __decorate([
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                DepartmentTree: DepartmentTree,
                AwaitCasetype: AwaitCasetype
            }
        })
    ], InrushTatics);
    return InrushTatics;
}(Vue));
export default InrushTatics;
