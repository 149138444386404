var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import EditTatics from "~/components/tatics-manage/case-tactics/edit-tatics.vue";
import ImportTatics from "~/components/tatics-manage/case-tactics/import-tatics.vue";
import { UploadFileService } from "~/services/common-service/upload-file.service";
import { StrategyConfigService } from "~/services/management-service/strategy-config.service";
import { SystemParamService } from "~/services/management-service/system-param.service";
import { StrategyService } from "~/services/manage-service/strategy.service";
//@Auth(92)
var CaseTactics = /** @class */ (function (_super) {
    __extends(CaseTactics, _super);
    function CaseTactics() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.strategyId = null;
        _this.loading = {
            state: false
        };
        _this.searchModel = {
            name: ""
        };
        _this.taticsDataSet = [];
        _this.dialog = {
            edit: false,
            module: false,
            list: false,
            import: false
        };
        return _this;
    }
    CaseTactics.prototype.created = function () { };
    CaseTactics.prototype.mounted = function () {
        this.refreshData();
    };
    /**
     * 修改按钮
     */
    CaseTactics.prototype.modifyClick = function (data) {
        this.strategyId = data.id;
        this.dialog.edit = true;
    };
    /**
     * 复制按钮
     */
    CaseTactics.prototype.copyClick = function (data) {
        var _this = this;
        this.$confirm("您确定要复制该策略吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        })
            .then(function () {
            _this.strategyService.copyStrategy(data.id, _this.loading).subscribe(function (data) {
                _this.$message.success("策略复制成功");
                _this.refreshData();
            }, function (_a) {
                var msg = _a.msg;
            });
        })
            .catch(function () { });
    };
    /**
     * 删除策略
     */
    CaseTactics.prototype.deleteClick = function (data) {
        var _this = this;
        this.$confirm("您确定要删除该策略吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
        })
            .then(function () {
            _this.strategyService.deleteStrategy(data.id).subscribe(function (data) {
                _this.refreshData();
            });
        })
            .catch(function () { });
    };
    /**
     * 新增策略
     */
    CaseTactics.prototype.addNewTactics = function () {
        this.strategyId = null;
        this.dialog.edit = true;
    };
    /**
     * 导入策略
     */
    CaseTactics.prototype.ImportTactics = function () {
        this.strategyId = null;
        this.dialog.import = true;
    };
    CaseTactics.prototype.refreshData = function () {
        var _this = this;
        this.strategyService
            .findAllStrategy(this.searchModel, this.pageService, this.sortService, this.loading)
            .subscribe(function (data) {
            _this.taticsDataSet = data;
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    CaseTactics.prototype.deactivated = function () {
        for (var v in this.dialog) {
            this.dialog[v] = false;
        }
    };
    /**
     * 下载导入策略表格
     */
    CaseTactics.prototype.downLoadImpClick = function () {
        var _this = this;
        this.systemParamService
            .downLoadImport({
            code: "import.strategy.template"
        })
            .subscribe(function (data) {
            if (data) {
                _this.uploadFileService
                    .getFileStreamById(data.fileId)
                    .subscribe(function () { return _this.$message.success("下载成功"); });
            }
        }, function (_a) {
            var msg = _a.msg;
        });
    };
    __decorate([
        Dependencies(PageService)
    ], CaseTactics.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], CaseTactics.prototype, "sortService", void 0);
    __decorate([
        Dependencies(StrategyConfigService)
    ], CaseTactics.prototype, "strategyConfigService", void 0);
    __decorate([
        Dependencies(SystemParamService)
    ], CaseTactics.prototype, "systemParamService", void 0);
    __decorate([
        Dependencies(UploadFileService)
    ], CaseTactics.prototype, "uploadFileService", void 0);
    __decorate([
        Dependencies(StrategyService)
    ], CaseTactics.prototype, "strategyService", void 0);
    CaseTactics = __decorate([
        Layout("workspace"),
        Component({
            components: {
                EditTatics: EditTatics,
                ImportTatics: ImportTatics,
                DataForm: DataForm,
                DataBox: DataBox
            }
        })
    ], CaseTactics);
    return CaseTactics;
}(Vue));
export default CaseTactics;
