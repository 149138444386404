var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import SvgIcon from "~/components/common/svg-icon.vue";
import { Prop } from "vue-property-decorator";
var TaticsItem = /** @class */ (function (_super) {
    __extends(TaticsItem, _super);
    function TaticsItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(TaticsItem.prototype, "rightNumber", {
        get: function () {
            var left = 0;
            var right = 0;
            this.data.forEach(function (value, index) {
                if (value.bracket.includes('(')) {
                    left++;
                }
                if (value.bracket.includes(')')) {
                    right++;
                }
            });
            if (left > right) {
                return left - right;
            }
            else {
                return 0;
            }
        },
        enumerable: false,
        configurable: true
    });
    /**
     * 删除
     */
    TaticsItem.prototype.deleteItem = function (i) {
        this.data.splice(i, 1);
    };
    /**
     * 增加
     */
    TaticsItem.prototype.addItem = function (v, i) {
        var obj = {
            leaf: true,
            variable: '',
            value: '',
            symbol: '',
            relation: '',
            bracket: '',
            canright: false
        };
        if (v.bracket === '' || v.symbol === '' || v.value === '' || v.variable === '') {
            return this.$message('请完善本条策略后再添加');
        }
        this.data.push(obj);
    };
    TaticsItem.prototype.braketDrop = function (v, i) {
        var left = 0;
        var right = 0;
        this.data.forEach(function (value, index) {
            if (index < i) {
                if (value.bracket.includes('(')) {
                    left++;
                }
                if (value.bracket.includes(')')) {
                    right++;
                }
            }
        });
        v.canright = left > right;
    };
    TaticsItem.prototype.variableChange = function (v, i) {
        v.value = '';
    };
    __decorate([
        Prop()
    ], TaticsItem.prototype, "data", void 0);
    TaticsItem = __decorate([
        Component({
            components: {
                SvgIcon: SvgIcon
            }
        })
    ], TaticsItem);
    return TaticsItem;
}(Vue));
export default TaticsItem;
