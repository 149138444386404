var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { managementService } from '~/config/server';
import { NetService } from '~/utils/net.service';
import { Inject, Debounce } from '~/core/decorator';
import { Service } from '~/core/service';
var SystemParamService = /** @class */ (function (_super) {
    __extends(SystemParamService, _super);
    function SystemParamService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /*
     * 修改参数表
     */
    SystemParamService.prototype.systemParamModify = function (data, loading) {
        return this.netService.send({
            server: managementService.systemParaController.systemParamModify,
            data: data,
            loading: loading
        });
    };
    /*
    * 系统参数查询
    */
    SystemParamService.prototype.systemParamQusery = function (data, page, sort, loading) {
        return this.netService.send({
            server: managementService.systemParaController.systemParamQuery,
            data: data,
            page: page,
            sort: sort,
            loading: loading
        });
    };
    /**
     *
     * @param data
     * @param loading
     */
    SystemParamService.prototype.downLoadImport = function (data, loading) {
        return this.netService.send({
            server: managementService.systemParaController.downLoadImport,
            data: data,
            loading: loading
        });
    };
    __decorate([
        Inject(NetService)
    ], SystemParamService.prototype, "netService", void 0);
    __decorate([
        Debounce()
    ], SystemParamService.prototype, "systemParamModify", null);
    __decorate([
        Debounce()
    ], SystemParamService.prototype, "systemParamQusery", null);
    return SystemParamService;
}(Service));
export { SystemParamService };
