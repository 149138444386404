var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Dependencies } from "~/core/decorator";
import { Emit, Prop } from "vue-property-decorator";
import { State, namespace } from "vuex-class";
import TaticsItem from "~/components/tatics-manage/case-tactics/edit-tatics/tatics-item.vue";
import InrushTatics from "~/components/tatics-manage/case-tactics/edit-tatics/inrush-tatics.vue";
import TaticsPreview from "~/components/tatics-manage/case-tactics/edit-tatics/tatics-preview.vue";
import { StrategyService } from "~/services/manage-service/strategy.service";
var taticManageModule = namespace("tatic-manage");
var EditTatics = /** @class */ (function (_super) {
    __extends(EditTatics, _super);
    function EditTatics() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.strategyProSet = [
            {
                leaf: true,
                variable: "",
                value: "",
                symbol: "",
                relation: "",
                bracket: "",
                canright: false,
            },
        ];
        _this.loading = {
            state: false,
        };
        _this.editTaicsData = {};
        _this.editModel = {
            id: _this.strategyId,
            // 策略名称
            name: "",
            // 策略类型
            strategyType: "",
            // 优先级
            priority: "",
            // 委托方ID
            principalIds: [],
            // 策略配置属性
            strategyProSet: [],
            cities: "",
        };
        _this.dialog = {
            inrush: false,
            preview: false,
            inrushTatics: false,
        };
        _this.editRules = {
            name: [{ required: true, message: "请输入策略名称", trigger: "blur" }],
            priority: [
                {
                    required: true,
                    type: "number",
                    message: "请选择优先级",
                    trigger: "change",
                },
            ],
            strategyType: [
                {
                    required: true,
                    message: "请选择分配类型",
                    trigger: "change",
                },
            ],
            type: [
                {
                    required: true,
                    type: "array",
                    message: "请选择案件池",
                    trigger: "change",
                },
            ],
        };
        return _this;
    }
    EditTatics.prototype.close = function () {
        this.reset();
    };
    EditTatics.prototype.refreshList = function () { };
    EditTatics.prototype.mounted = function () { };
    EditTatics.prototype.previewClose = function () {
        this.dialog.preview = false;
    };
    //预览结果
    EditTatics.prototype.preview = function () {
        var _this = this;
        var form = this.$refs.form;
        form.validate(function (valid) {
            if (!valid) {
                return;
            }
            _this.dialog.preview = true;
        });
    };
    // 下一步
    EditTatics.prototype.nextStep = function () {
        var _this = this;
        var form = this.$refs.form;
        form.validate(function (valid) {
            if (!valid) {
                return;
            }
            if (_this.strategyProSet.length === 1) {
                if (_this.strategyProSet[0].bracket === "" && _this.strategyProSet[0].symbol === "" && _this.strategyProSet[0].value === "" && _this.strategyProSet[0].variable === "") {
                }
                else if (_this.strategyProSet[0].bracket !== "" && _this.strategyProSet[0].symbol !== "" && _this.strategyProSet[0].value !== "" && _this.strategyProSet[0].variable !== "") {
                }
                else {
                    return _this.$message("请完善本条规则！");
                }
            }
            else {
                var lastLength = _this.strategyProSet.length - 1;
                if (_this.strategyProSet[lastLength].bracket === "" ||
                    _this.strategyProSet[lastLength].symbol === "" ||
                    _this.strategyProSet[lastLength].value === "" ||
                    _this.strategyProSet[lastLength].variable === "") {
                    return _this.$message("请完善最后一条规则或删除！");
                }
            }
            _this.dialog.inrushTatics = true;
            _this.editModel.strategyProSet = _this.strategyProSet;
            _this.editTaicsData = _this.editModel;
        });
    };
    //新增成功
    EditTatics.prototype.inrushSuccess = function () {
        this.dialog.inrushTatics = false;
        this.refreshList();
        this.close();
    };
    EditTatics.prototype.selectChange = function (value) {
        var currentPrincipal = this.principalList.find(function (v) { return v.id === value; });
        this.editModel.principalName = (currentPrincipal || {}).principalName;
    };
    EditTatics.prototype.refresh = function () {
        var _this = this;
        this.editModel = {
            id: this.strategyId,
            name: "",
            strategyType: "",
            priority: "",
            principalIds: [],
            strategyProSet: [],
            cities: "",
        };
        if (!!this.strategyId) {
            // this.loading.state = true;
            this.strategyService.getStrategy(this.strategyId, this.loading).subscribe(function (data) {
                if (data)
                    _this.editModel = data;
                if (data.formulaJson.length) {
                    _this.strategyProSet = JSON.parse(data.formulaJson).map(function (v) { return ({
                        leaf: v.leaf,
                        variable: v.variable,
                        value: v.value,
                        symbol: v.symbol,
                        relation: v.relation,
                        bracket: v.bracket,
                        canright: v.canright,
                    }); });
                }
                else {
                    _this.strategyProSet = [
                        {
                            leaf: true,
                            variable: "",
                            symbol: "",
                            value: "",
                            relation: "",
                            bracket: "",
                        },
                    ];
                }
            });
        }
        else {
            this.strategyProSet = [
                {
                    leaf: true,
                    variable: "",
                    symbol: "",
                    value: "",
                    relation: "",
                    bracket: "",
                },
            ];
        }
    };
    EditTatics.prototype.reset = function () {
        var form = this.$refs.form;
        this.editModel.id = null;
        form.resetFields();
        this.strategyProSet = [];
    };
    __decorate([
        Dependencies(StrategyService)
    ], EditTatics.prototype, "strategyService", void 0);
    __decorate([
        State
    ], EditTatics.prototype, "principalList", void 0);
    __decorate([
        State
    ], EditTatics.prototype, "storeList", void 0);
    __decorate([
        taticManageModule.State("tacticsFlag")
    ], EditTatics.prototype, "tacticsFlag", void 0);
    __decorate([
        taticManageModule.Mutation("updateTacticsFlag")
    ], EditTatics.prototype, "updateTacticsFlag", void 0);
    __decorate([
        Emit("close")
    ], EditTatics.prototype, "close", null);
    __decorate([
        Emit("refreshList")
    ], EditTatics.prototype, "refreshList", null);
    __decorate([
        Prop()
    ], EditTatics.prototype, "strategyId", void 0);
    EditTatics = __decorate([
        Component({
            components: {
                TaticsPreview: TaticsPreview,
                InrushTatics: InrushTatics,
                TaticsItem: TaticsItem,
            },
        })
    ], EditTatics);
    return EditTatics;
}(Vue));
export default EditTatics;
